import { useDispatch, useSelector } from 'react-redux';
import { toastNotifyError } from 'src/components/Common/Toast';
import { setPaymentModalVisiblity, updateOrderState } from 'src/redux/actions';
import { canDisablePlaceOrder } from 'src/utils/canDisablePlaceOrder';
import {
  MINIMUM_ORDER_AMOUNT,
  ORDER_PAYMENT_TYPE,
  PREPAID_POSTPAID_METHODS,
} from 'src/utils/constants';
import { isCodDisabledForOrder } from 'src/utils/isCodDisabledForOrder';
import router from 'next/router';
import { RootState } from 'src/redux/reducers';
import useStoreContactDetails from './useStoreContactDetails';
import { getWhatAppRedirectionUrlCODRequest } from 'src/utils/getWhatsAppRedirectionUrl';

export function usePaymentOptionHandler() {
  const dispatch = useDispatch();
  const { storePhone } = useStoreContactDetails();

  const { orderDetails, storeInfo } = useSelector((state: RootState) => {
    return {
      orderDetails: state.orderReducer.orderDetails,
      storeInfo: state.storeReducer.store,
    };
  });

  const onCashOptionHandle = () => {
    if (canDisablePlaceOrder()) {
      return;
    }
    if (
      isCodDisabledForOrder() &&
      orderDetails?.buyer_pay_value <= MINIMUM_ORDER_AMOUNT
    ) {
      toastNotifyError(`Cannot place order below ${MINIMUM_ORDER_AMOUNT}`);
      return;
    }
    // Check if the payment is for prepaid order
    if (
      storeInfo?.services?.payment_method === PREPAID_POSTPAID_METHODS.BOTH &&
      orderDetails.prepaid_flag === ORDER_PAYMENT_TYPE.PREPAID
    ) {
      dispatch(updateOrderState(orderDetails?.order_hash, router));
    } else {
      dispatch(setPaymentModalVisiblity(false));
      if (storePhone) {
        // Create whatsapp intent for web and open in next tab
        window.open(
          getWhatAppRedirectionUrlCODRequest(
            orderDetails?.buyer_pay_value,
            orderDetails?.order_id
          ),
          '_blank'
        );
      }
    }
  };
  return { onCashOptionHandle };
}
